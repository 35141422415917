import { Store } from "@reduxjs/toolkit";
import { selectSection } from "../store/pageSelector";
import { addGeneratedProblems, updateSection } from "../store/pageSlice";
import { RootState } from "../store/store";
import {
  GenerateReq,
  GeneratedProblem,
  Operations,
  Operators,
  Page,
  Problem,
  ProblemGenerated,
} from "../types";
import { convertSectionToAPIModel } from "../util/problems";

const apiProtocol = window.location.protocol;
const apiHostName = window.location.hostname;
const appPort = window.location.port;
let apiPort = appPort === "" ? appPort : ":9999";
const apiHost = `${apiProtocol}//${apiHostName}${apiPort}`;
export async function generateProblems(
  spec: Array<Page>,
  fileName: string = "",
  noDuplicates: boolean = true,
  problemNumbers: boolean = false,
  generateKey: boolean = true
): Promise<void> {
  if (!fileName) {
    fileName = "MathWorksheet-" + Date.now() + ".pdf";
  }
  const req: GenerateReq = {
    noDuplicates,
    fileName,
    showProblemNumbers: problemNumbers ?? false,
    generateKey,
    pages: spec,
  };
  const response = await fetch(`${apiHost}/worksheets/generate`, {
    method: "post",
    headers: {
      Accept: "application/octect-stream",
      "Content-type": "application/json",
    },
    body: JSON.stringify(req),
  });
  if (!response.ok) {
    const msg = await response.text();
    throw new Error(
      "An error was returned from the server: " + response.status + " " + msg
    );
  } else {
    const a = document.createElement("a");
    try {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = req.fileName;
      document.body.appendChild(a);
      a.click();
    } finally {
      a.remove();
    }
  }
}

export async function generatePreview(
  sectonId: string,
  store: Store<RootState>,
  noDuplicates: boolean = true
): Promise<void> {
  const sectionSpec = selectSection(store.getState().pages, sectonId);
  const spec: Array<Page> = [
    {
      sections: [
        convertSectionToAPIModel(sectionSpec, store.getState().pages, true),
      ],
    },
  ];
  const req: GenerateReq = {
    noDuplicates,
    fileName: "",
    showProblemNumbers: false,
    generateKey: false,
    pages: spec,
  };
  const response = await fetch(`${apiHost}/worksheets/generate`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(req),
  });
  if (!response.ok) {
    const section = selectSection(store.getState().pages, sectonId);
    store.dispatch(updateSection({ ...section, problemPreviewStatus: "none" }));
    const msg = await response.text();
    throw new Error(
      "An error was returned from the server: " + response.status + " " + msg
    );
  } else {
    const responsePage: Array<Page> = await response.json();
    if (responsePage?.length) {
      if (responsePage[0].sections?.length) {
        const ids: Array<string> = [];
        store.dispatch(
          addGeneratedProblems(
            responsePage[0].sections[0].problems.map((p) => {
              const gp = problemToGeneratedProblem(p);
              ids.push(gp.id);
              return gp;
            })
          )
        );
        const section = selectSection(store.getState().pages, sectonId);
        store.dispatch(
          updateSection({
            ...section,
            generatedProblemIds: ids,
            problemPreviewStatus: "success",
          })
        );
      } else {
        console.warn("The first page in the response had no sections.");
      }
    } else {
      console.warn("No pages returned in preview response.");
    }
  }
}
let problemIDCount: number = 0;
const problemToGeneratedProblem = (problem: Problem): GeneratedProblem => {
  const operationIndex =
    Operations.findIndex((op) => op === problem.operation) ?? 0;
  return {
    id: `generatedProblem${problemIDCount++}`,
    operands: (problem as ProblemGenerated).operands.map((o) => o),
    operation: problem.operation,
    operator: Operators[operationIndex],
    solution: (problem as ProblemGenerated).solution,
  };
};
