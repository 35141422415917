import {
  OperandConfigSpec,
  OperandQuickOption,
  Operation,
  ProblemSpec,
} from "../types";

export const getOperandSpec = (
  problem: ProblemSpec,
  operandIndex: number
): OperandConfigSpec => {
  let fetchOperandsFunc: (c?: number) => Array<OperandConfigSpec>;
  switch (problem.operation) {
    case "add":
      fetchOperandsFunc = getAdditionOperands;
      break;
    case "subtract":
      fetchOperandsFunc = getSubtractionOperands;
      break;
    case "multiply":
      fetchOperandsFunc = getMultiplicationOperands;
      break;
    case "divide":
      fetchOperandsFunc = getDivisionOperands;
      break;
  }
  return fetchOperandsFunc(operandIndex + 1)[operandIndex];
};

const multiplicationQuickOptions: Array<OperandQuickOption> = [
  { label: "Basic 0-12", range: [0, 12] },
  { label: "Basic 1-12", range: [1, 12] },
  { label: "Two-digit", range: [10, 99] },
  { label: "Three-digit", range: [100, 999] },
  { label: "Four-digit", range: [1000, 9999] },
];

const additionQuickOptions: Array<OperandQuickOption> = [
  { label: "Single digit", range: [0, 9] },
  { label: "Two-digit", range: [10, 99] },
  { label: "Three-digit", range: [100, 999] },
  { label: "Four-digit", range: [1000, 9999] },
  { label: "Five-digit", range: [10000, 99999] },
];

const subtractionQuickOptions: Array<OperandQuickOption> = [
  { label: "Single digit", range: [0, 9] },
  { label: "Two-digit", range: [10, 99] },
  { label: "Three-digit", range: [100, 999] },
  { label: "Four-digit", range: [1000, 9999] },
  { label: "Five-digit", range: [10000, 99999] },
];

const divisionQuickOptions: Array<OperandQuickOption> = [
  { label: "Basic 1-9", range: [1, 9] },
  { label: "Two-digit", range: [10, 99] },
  { label: "Three-digit", range: [100, 999] },
  { label: "Four-digit", range: [1000, 9999] },
  { label: "Five-digit", range: [10000, 99999] },
];

const multiplicationOperands: Array<OperandConfigSpec> = [
  {
    label: "Multiplicand",
    operation: "multiply",
    possibleRange: [1, 1000],
    quickOptions: multiplicationQuickOptions,
  },
  {
    label: "Multiplier",
    operation: "multiply",
    possibleRange: [1, 1000],
    quickOptions: multiplicationQuickOptions,
  },
];

const additionOperands: Array<OperandConfigSpec> = [
  {
    label: "Addend",
    operation: "add",
    possibleRange: [0, 100000],
    quickOptions: additionQuickOptions,
  },
  {
    label: "Addend",
    operation: "add",
    possibleRange: [0, 100000],
    quickOptions: additionQuickOptions,
  },
];

const subtractionOperands: Array<OperandConfigSpec> = [
  {
    label: "Minuend",
    operation: "subtract",
    possibleRange: [1, 100000],
    quickOptions: subtractionQuickOptions,
  },
  {
    label: "Subtrahend",
    operation: "subtract",
    possibleRange: [1, 100000],
    quickOptions: subtractionQuickOptions,
  },
];

const divisionOperands: Array<OperandConfigSpec> = [
  {
    label: "Dividend",
    operation: "divide",
    possibleRange: [1, 100000],
    quickOptions: divisionQuickOptions,
  },
  {
    label: "Divisor",
    operation: "divide",
    possibleRange: [1, 10000],
    quickOptions: divisionQuickOptions,
  },
];

const getOperands = (
  operation: Operation,
  count: number
): Array<OperandConfigSpec> => {
  const ret: Array<OperandConfigSpec> = [];
  let specs: Array<OperandConfigSpec> = [];
  switch (operation) {
    case "add":
      specs = additionOperands;
      break;
    case "subtract":
      specs = subtractionOperands;
      break;
    case "multiply":
      specs = multiplicationOperands;
      break;
    case "divide":
      specs = divisionOperands;
      break;
    default:
      console.error("unknown operation " + operation);
      return [];
  }
  for (let i = 0; i < count; i++) {
    if (i < specs.length) {
      ret.push(specs[i]);
    } else {
      ret.push(specs[specs.length - 1]);
    }
  }
  return ret;
};

const getMultiplicationOperands = (
  count: number = 2
): Array<OperandConfigSpec> => {
  return getOperands("multiply", count);
};

const getDivisionOperands = (count: number = 2): Array<OperandConfigSpec> => {
  return getOperands("divide", Math.min(count, 2));
};
const getAdditionOperands = (count: number = 2): Array<OperandConfigSpec> => {
  return getOperands("add", count);
};
const getSubtractionOperands = (
  count: number = 2
): Array<OperandConfigSpec> => {
  return getOperands("subtract", count);
};
