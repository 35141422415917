import { CircularProgress, Stack } from "@mui/joy";
import { GeneratedProblem, SectionSpec } from "../types";
import { Fragment, useMemo } from "react";
import { selectGeneratedProblems } from "../store/pageSelector";
import { useAppSelector } from "../store/hooks";
import { useStore } from "react-redux";
import { RootState } from "../store/store";
import { ProblemEditor } from "./ProblemEditor";

export interface ProblemPreviewProps {
  section: SectionSpec;
}

export const ProblemPreview = ({ section }: ProblemPreviewProps) => {
  const store = useStore<RootState>();
  const problems: Array<GeneratedProblem> = useAppSelector(() => {
    return selectGeneratedProblems(store.getState().pages, section.id);
  });
  const generatedProblems = useMemo(() => {
    const probs = problems.map((p, ix) => {
      return <ProblemEditor problem={p} problemNumber={ix + 1} />;
    });
    return <Stack direction={"column"}>{probs}</Stack>;
  }, [problems]);
  return section.problemPreviewStatus === "busy" ? (
    <CircularProgress
      sx={{ margin: ".5rem" }}
      variant="plain"
      size="sm"
      value={60}
    />
  ) : (
    <Fragment>{generatedProblems}</Fragment>
  );
};
