import {
  Box,
  Input,
  Option,
  Select,
  Slider,
  Stack,
  Typography,
} from "@mui/joy";
import { OperandQuickOption, OperandSpec } from "../types";
import {
  Fragment,
  ReactElement,
  useCallback,
  useMemo,
  ChangeEvent,
  useId,
  useState,
} from "react";

export interface OperandProps {
  onChange: (operand: OperandSpec) => void;
  operand: OperandSpec;
}

export const Operand = ({ operand, onChange }: OperandProps) => {
  const minInputId = useId();
  const maxInputId = useId();
  const [maxInputValueBlank, setMaxInputValueBlank] = useState<boolean>(false);
  const [minInputValueBlank, setMinInputValueBlank] = useState<boolean>(false);
  const label = operand.label;
  const handleSliderChange = useCallback(
    (newValue: [number, number]) => {
      if (newValue[0] >= newValue[1]) {
        return;
      }
      const updatedOperand: OperandSpec = { ...operand, range: [...newValue] };
      onChange(updatedOperand);
    },
    [onChange, operand]
  );

  const onMinInputChange = useCallback(
    (value: string) => {
      if (value === "") {
        setMinInputValueBlank(true);
        return;
      } else {
        setMinInputValueBlank(false);
      }
      if (!value) {
        return;
      }
      const valNum = parseInt(value);
      if (isNaN(valNum)) {
        return;
      }
      if (valNum >= operand.range[1]) {
        return;
      }
      const updatedOperand: OperandSpec = {
        ...operand,
        range: [valNum, operand.range[1]],
      };
      onChange(updatedOperand);
    },
    [onChange, operand, setMinInputValueBlank]
  );

  const onMaxInputChange = useCallback(
    (value: string) => {
      if (value === "") {
        setMaxInputValueBlank(true);
        return;
      } else {
        setMaxInputValueBlank(false);
      }
      if (!value) {
        return;
      }
      const valNum = parseInt(value);
      if (isNaN(valNum)) {
        return;
      }
      if (valNum <= operand.range[0]) {
        return;
      }
      const updatedOperand: OperandSpec = {
        ...operand,
        range: [operand.range[0], valNum],
      };
      onChange(updatedOperand);
    },
    [onChange, operand, setMaxInputValueBlank]
  );

  const onSelectChange = useCallback(
    (_evt: any, newValue: string | null) => {
      if (newValue === null) {
        return;
      }
      const ix = parseInt(newValue);
      if (isNaN(ix)) {
        return;
      }
      const updatedOperand: OperandSpec = {
        ...operand,
        quickOptionSelectedIndex: ix,
        range: operand.quickOptions?.[ix]?.range ?? operand.range,
      };
      onChange(updatedOperand);
    },
    [onChange, operand]
  );

  const inputRange = useMemo(() => {
    return [
      minInputValueBlank ? "" : operand.range[0] + "",
      maxInputValueBlank ? "" : operand.range[1] + "",
    ];
  }, [minInputValueBlank, maxInputValueBlank, operand.range]);

  const select = useMemo((): ReactElement => {
    const optionSpecs = operand.quickOptions ?? [];
    const options: Array<ReactElement> = optionSpecs.map(
      (op: OperandQuickOption, i) => {
        return <Option value={i + ""}>{op.label}</Option>;
      }
    );
    const selectedIndex = operand.quickOptionSelectedIndex ?? 0;
    return (
      <Select onChange={onSelectChange} value={selectedIndex + ""}>
        {[...options, <Option value={"-1"}>Custom</Option>]}
      </Select>
    );
  }, [operand, onSelectChange]);

  const content = useMemo((): ReactElement => {
    let ix = -1;
    if (operand.quickOptionSelectedIndex !== undefined) {
      ix = operand.quickOptionSelectedIndex;
    } else {
      ix = 0;
    }
    if (ix === -1) {
      let range = [
        Math.max(operand.range[0], operand.possibleRange[0]),
        Math.min(operand.range[1], operand.possibleRange[1]),
      ] ?? [0, 12];

      return (
        <Stack my=".5rem" direction="row" gap={2}>
          <Stack direction="row" gap={0.5}>
            <label htmlFor={minInputId} style={{ marginTop: ".25rem" }}>
              Min:
            </label>
            <Input
              size="sm"
              slotProps={{ input: { id: minInputId } }}
              sx={{ width: "5rem" }}
              onBlur={() => setMinInputValueBlank(false)}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                onMinInputChange(evt.target.value);
              }}
              value={inputRange[0]}
            />
          </Stack>
          <Box sx={{ width: 300, maxHeight: 0 }}>
            <Slider
              value={range}
              min={operand.possibleRange[0]}
              max={operand.possibleRange[1]}
              onChange={(_evt, value) => {
                handleSliderChange(value as [number, number]);
              }}
              valueLabelDisplay="off"
            />
          </Box>
          <Stack direction="row" gap={0.5}>
            <label htmlFor={maxInputId} style={{ marginTop: ".25rem" }}>
              Max:
            </label>
            <Input
              sx={{ width: "5rem" }}
              slotProps={{ input: { id: maxInputId } }}
              size="sm"
              onBlur={() => setMaxInputValueBlank(false)}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                onMaxInputChange(evt.target.value);
              }}
              value={inputRange[1]}
            />
          </Stack>
        </Stack>
      );
    } else {
      return <Fragment />;
    }
  }, [
    handleSliderChange,
    maxInputId,
    minInputId,
    inputRange,
    onMaxInputChange,
    onMinInputChange,
    operand.quickOptionSelectedIndex,
    operand.range,
    operand.possibleRange,
  ]);

  return (
    <Stack pt=".75rem">
      <Typography color="primary" level="body-xs">
        {label} range
      </Typography>
      {select}
      {content}
    </Stack>
  );
};
