// these need to be in sync with the java models in com.ideaspill.ws.model.rep
export type Operation = "add" | "subtract" | "multiply" | "divide";

export interface ProblemReqSpec {
  params?: Record<string, any>;
  operandRanges: Array<[number, number]>;
}

export interface ProblemGenerated {
  operands: Array<number>;
  solution?: number;
}

type basicProblem = {
  operation: Operation;
};

export type Problem = basicProblem & (ProblemReqSpec | ProblemGenerated);
export type Section = {
  instructions?: string;
  title?: string;
  problemFontSize?: fontSize;
  problems: Array<Problem>;
};
export type Page = {
  showNameField?: boolean;
  sections: Array<Section>;
};
export type GenerateReq = {
  fileName: string;
  pages: Array<Page>;
  noDuplicates?: boolean;
  showProblemNumbers?: boolean;
  generateKey?: boolean;
};

export type view = "quick" | "custom";
export type solutionOption = "none" | "some" | "all";
export type operator = "+" | "-" | "*" | "/";
export type fontSize = "xs" | "sm" | "m" | "l" | "xl";
export type previewStatus = "none" | "busy" | "success" | "error";
export type PageSpec = {
  num: number;
  sections: Array<SectionSpec>;
} & Omit<Page, "sections">;

export type SectionSpec = {
  id: string;
  mixProblems: boolean;
  problemFontSize?: fontSize;
  problemIds: Array<string>;
  problemPreviewStatus?: previewStatus;
  generatedProblemIds?: Array<string>;
} & Omit<Section, "problems">;

export type ProblemSpec = {
  id: string;
  count: number;
  operands: Array<OperandSpec>;
} & Omit<ProblemReqSpec, "operandRanges"> &
  basicProblem;

export type GeneratedProblem = {
  id: string;
  operator: operator;
} & basicProblem &
  ProblemGenerated;

export type RegrouperSpec = {
  withRegrouping?: solutionOption;
} & ProblemSpec;

export type AdditionProblemSpec = {
  operator: "+";
} & RegrouperSpec;

export type SubtractionProblemSpec = {
  withNegatives?: solutionOption;
  operator: "-";
} & RegrouperSpec;

export type DivisionProblemSpec = {
  operator: "/";
  withRemainders?: solutionOption;
} & ProblemSpec;

export type MultiplicationSpec = {
  operator: "*";
} & ProblemSpec;

export type OperandQuickOption = {
  label: string;
  range: [number, number];
};

export type OperandConfigSpec = {
  label: string;
  operation: Operation;
  possibleRange: [number, number];
  quickOptions?: Array<OperandQuickOption>;
  quickOptionSelectedIndex?: number;
};
export type OperandSpec = {
  range: [number, number];
} & OperandConfigSpec;

// Operations Operators and Calculators are expected to remain in parallel
export const Operations: Array<Operation> = [
  "multiply",
  "divide",
  "add",
  "subtract",
];
export const Operators: Array<operator> = ["*", "/", "+", "-"];
export const Calculators: Array<(operands: Array<number>) => any> = [
  (ops) => ops.reduce((op1, op2) => op1 * op2, 1),
  (ops) => {
    const op1 = ops[0] ? ops[0] : 1;
    const op2 = ops[1] ? ops[1] : 1;
    const bulk = Math.floor(op1 / op2);
    const remainder = op1 % op2;
    if (remainder > 0) {
      return `${bulk}R${remainder}`;
    }
    return bulk;
  },
  (ops) => ops.reduce((op1, op2) => op1 + op2, 0),
  (ops) => ops.reduce((op1, op2) => op1 - op2),
];
export const defaultSection: SectionSpec = {
  id: "",
  mixProblems: false,
  problemIds: [],
  instructions: "Find the solution to each problem.",
};

export const defaultProblemCount = 10;

export const defaultProblem: MultiplicationSpec = {
  id: "",
  count: defaultProblemCount,
  operation: "multiply",
  operands: [],
  operator: "*",
};

export const fontSizes: Array<fontSize> = ["xs", "sm", "m", "l", "xl"];
