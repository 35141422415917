import { createSelector } from "@reduxjs/toolkit";
import { PageState } from "./pageSlice";

const empty: Array<any> = [];
const selectPageState = (state: PageState) => state;
export const selectPages = createSelector(
  selectPageState,
  (state: PageState) => {
    return state.pages;
  }
);

export const selectSections = createSelector(
  [selectPageState, (state: PageState, num: number) => num],
  (state: PageState, num: number) => {
    return (
      state.pages[num]?.sectionsIds?.map((sid) => state.sectionsById[sid]) ??
      empty
    );
  }
);

export const selectSection = createSelector(
  [selectPageState, (state: PageState, id: string) => id],
  (state: PageState, id: string) => {
    return state.sectionsById[id];
  }
);

export const selectProblems = createSelector(
  [selectPageState, (state: PageState, sectionId: string) => sectionId],
  (state: PageState, sectionId: string) => {
    return (
      state.sectionsById[sectionId]?.problemIds?.map(
        (pid) => state.problemsById[pid]
      ) ?? empty
    );
  }
);

export const selectGeneratedProblems = createSelector(
  [selectPageState, (state: PageState, sectionId: string) => sectionId],
  (state: PageState, sectionId: string) => {
    return (
      state.sectionsById[sectionId]?.generatedProblemIds?.map(
        (pid) => state.generatedProblemsById[pid]
      ) ?? empty
    );
  }
);

export const selectProblem = createSelector(
  [selectPageState, (state: PageState, problemId: string) => problemId],
  (state: PageState, problemId: string) => {
    return state.problemsById[problemId];
  }
);

export const selectGeneratedProblem = createSelector(
  [selectPageState, (state: PageState, problemId: string) => problemId],
  (state: PageState, problemId: string) => {
    return state.generatedProblemsById[problemId];
  }
);
