export const generateDateFilenameSuffix = (now?: Date): string => {
  now = now ?? new Date();
  let hours = now.getHours();
  let ampm = "AM";
  if (hours >= 12) {
    ampm = "pm";
    hours = hours - 12;
  }
  if (hours === 0) {
    hours = 12;
  }
  return `${now.toLocaleString("default", {
    month: "short",
  })}-${now.getDate()}-${now.getFullYear()} ${hours}_${zeroPad(
    now.getMinutes()
  )}_${zeroPad(now.getSeconds())}${ampm}`;
};

export const zeroPad = (num: number, len: number = 2): string => {
  let numStr = num + "";
  while (numStr.length < len) {
    numStr = "0" + numStr;
  }
  return numStr;
};
