import { useState, useCallback, Fragment } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { QuickActions } from "./components/QuickActions";
import { view } from "./types";
import { CustomGeneration } from "./components/CustomGeneration";

export default function JoyOrderDashboardTemplate() {
  const [activeView, setActiveView] = useState<view>("quick");
  const onNavItem = useCallback(
    (item: view) => {
      if (item && activeView !== item) {
        setActiveView(item);
      }
    },
    [activeView, setActiveView]
  );
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
        <Sidebar onItemSelect={onNavItem} />
        <Header />
        <Box
          component="main"
          className="MainContent"
          sx={{
            pt: {
              xs: "calc(12px + var(--Header-height))",
              md: 3,
            },
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
            overflow: "auto",
          }}
        >
          {activeView === "quick" ? (
            <QuickActions
              onJumpToCustom={() => {
                onNavItem("custom");
              }}
            />
          ) : activeView === "custom" ? (
            <CustomGeneration />
          ) : (
            <Fragment />
          )}
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
