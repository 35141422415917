import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Input,
  Option,
  Select,
  Slider,
  Stack,
  Typography,
} from "@mui/joy";
import {
  Operation,
  Operations,
  ProblemSpec,
  defaultProblemCount,
} from "../types";
import {
  ReactElement,
  Fragment,
  useCallback,
  useState,
  useMemo,
  ChangeEvent,
} from "react";
import { MultiplicationProblem } from "./MultiplicationProblem";
import { useDispatch, useSelector, useStore } from "react-redux";
import { removeProblemSpec, updateProblemSpec } from "../store/pageSlice";
import { RootState } from "../store/store";
import { selectProblem, selectProblems } from "../store/pageSelector";
import { DivisionProblem } from "./DivisionProblem";
import { getOperandSpec } from "../util/operation";
import { AdditionProblem } from "./AdditionProblem";
import { SubtractionProblem } from "./SubtractionProblem";
import Close from "@mui/icons-material/Close";
export interface ProblemProps {
  problemId: string;
  sectionId: string;
}
const maxProblemCount = 200;
export const Problem = ({ problemId, sectionId }: ProblemProps) => {
  const dispatch = useDispatch();
  const store = useStore<RootState>();
  const [countInputBlank, setCountInputBlank] = useState(false);
  const model: ProblemSpec = useSelector(
    () =>
      selectProblem(store.getState().pages, problemId) ?? ({} as ProblemSpec)
  );
  const sectionProblemCount = useSelector(() => {
    return selectProblems(store.getState().pages, sectionId).length;
  });
  const options = Operations.map((op) => (
    <Option key={op} value={op}>
      {op}
    </Option>
  ));
  const onCountChange = useCallback(
    (newValue: string | null) => {
      if (newValue === "") {
        setCountInputBlank(true);
        return;
      }
      setCountInputBlank(false);
      let updatedCount = defaultProblemCount;
      if (!!newValue) {
        const asInt = parseInt(newValue);
        if (!isNaN(asInt)) {
          updatedCount = Math.max(1, Math.min(asInt, maxProblemCount));
        }
      }
      const updatedProblem: ProblemSpec = {
        ...model,
        count: updatedCount,
      };
      dispatch(updateProblemSpec(updatedProblem));
    },
    [model, dispatch, setCountInputBlank]
  );
  const countInput = useMemo(() => {
    if (countInputBlank) {
      return "";
    }
    return model.count + "";
  }, [model, countInputBlank]);
  const onRemoveProblem = useCallback(() => {
    dispatch(removeProblemSpec([sectionId, problemId]));
  }, [sectionId, problemId, dispatch]);
  const onSelectChange = useCallback(
    (event: React.SyntheticEvent | null, newValue: string | null) => {
      if (model.operation === newValue) {
        return;
      }
      const updatedProblem: ProblemSpec = {
        ...model,
        operation: newValue as Operation,
      };
      const op1 = getOperandSpec(updatedProblem, 0);
      const op2 = getOperandSpec(updatedProblem, 1);
      updatedProblem.operands = [
        {
          ...op1,
          range: [
            op1.quickOptions?.[0].range[0] ?? 0,
            op1.quickOptions?.[0].range[1] ?? 12,
          ],
        },
        {
          ...op2,
          range: [
            op2.quickOptions?.[0].range[0] ?? 0,
            op2.quickOptions?.[0].range[1] ?? 12,
          ],
        },
      ];
      dispatch(updateProblemSpec(updatedProblem));
    },
    [dispatch, model]
  );
  let operationContent: ReactElement = <Fragment />;
  switch (model.operation) {
    case "divide":
      operationContent = (
        <DivisionProblem key={sectionId} sectionId={sectionId} model={model} />
      );
      break;
    case "add":
      operationContent = (
        <AdditionProblem key={sectionId} sectionId={sectionId} model={model} />
      );
      break;
    case "subtract":
      operationContent = (
        <SubtractionProblem
          key={sectionId}
          sectionId={sectionId}
          model={model}
        />
      );
      break;
    case "multiply":
      operationContent = (
        <MultiplicationProblem
          key={sectionId}
          sectionId={sectionId}
          model={model}
        />
      );
      break;
  }
  return (
    <Card sx={{ marginTop: "1rem" }} color={"primary"} variant="outlined">
      <CardContent>
        <Stack>
          <Stack direction={"row"} gap={5} pb={"1rem"}>
            <Stack direction={"column"}>
              <Typography level="body-sm" color="primary">
                Problem type:
              </Typography>
              <Select
                onChange={onSelectChange}
                value={model.operation}
                defaultValue={Operations[0]}
                size="sm"
                variant="solid"
                sx={{ flexGrow: 2, width: "200px" }}
              >
                {options}
              </Select>
            </Stack>
            <Stack direction={"column"} width={"200px"}>
              <Box>
                <Typography level="body-sm" color="primary">
                  How many?
                </Typography>
                <Stack direction={"row"} gap={2} flexGrow={2} width={"100%"}>
                  <Slider
                    value={model.count}
                    step={null}
                    marks={[
                      { value: 1 },
                      { value: 5 },
                      { value: 10 },
                      { value: 15 },
                      { value: 20 },
                      { value: 25 },
                      { value: 50 },
                      { value: 75 },
                      { value: 100 },
                    ]}
                    sx={{ paddingTop: 0, paddingBottom: 0 }}
                    onChange={(_, value) => {
                      onCountChange(value + "");
                    }}
                  />
                  <Input
                    size="sm"
                    sx={{ width: "5rem" }}
                    value={countInput}
                    onBlur={() => {
                      setCountInputBlank(false);
                    }}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                      onCountChange(evt.target.value)
                    }
                  />
                </Stack>
              </Box>
            </Stack>
          </Stack>
          {operationContent}
        </Stack>
      </CardContent>
      {sectionProblemCount > 1 ? (
        <CardActions>
          <Box flexGrow="4" />
          <Button
            startDecorator={<Close />}
            onClick={() => {
              onRemoveProblem();
            }}
            size="sm"
            variant="outlined"
          >
            Remove
          </Button>
        </CardActions>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};
