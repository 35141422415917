import { PageModel, PageState } from "../store/pageSlice";
import {
  GeneratedProblem,
  Page,
  Problem,
  ProblemSpec,
  Section,
  SectionSpec,
} from "../types";

export const QUICK_PROBLEM_COUNT = 49;

export const generateQuickMultiplication5 = (): Array<Page> => {
  const pages: Array<Page> = [];
  for (let p = 0; p < 5; p++) {
    const problemSpecs: Array<Problem> = [];
    for (let i = 0; i < QUICK_PROBLEM_COUNT; i++) {
      const problem: Problem = {
        operation: "multiply",
        operandRanges: [
          [0, 13],
          [0, 13],
        ],
      };
      problemSpecs.push(problem);
    }
    pages[p] = {
      showNameField: true,
      sections: [
        {
          instructions: "Multiply to find each product. Think fast!",
          problems: problemSpecs,
        },
      ],
    };
  }
  return pages;
};

export const generateQuickMultiplication = (): Array<Page> => {
  const problemSpecs: Array<Problem> = [];
  for (let i = 0; i < QUICK_PROBLEM_COUNT; i++) {
    const problem: Problem = {
      operation: "multiply",
      operandRanges: [
        [0, 13],
        [0, 13],
      ],
    };
    problemSpecs.push(problem);
  }
  return [
    {
      showNameField: true,
      sections: [
        {
          instructions: "Multiply to find each product. Think fast!",
          problems: problemSpecs,
        },
      ],
    },
  ];
};

export const generateQuickDivision = (): Array<Page> => {
  const problemSpecs: Array<Problem> = [];
  for (let i = 0; i < QUICK_PROBLEM_COUNT; i++) {
    problemSpecs.push({
      operation: "divide",
      operandRanges: [
        [1, 145],
        [1, 13],
      ],
      params: { noRemainders: true },
    });
  }
  return [
    {
      showNameField: true,
      sections: [
        {
          instructions: "Divide to find each quotient. Think fast!",
          problems: problemSpecs,
        },
      ],
    },
  ];
};

export const generateQuickSubtraction = (): Array<Page> => {
  const problemSpecs: Array<Problem> = [];
  for (let i = 0; i < 24; i++) {
    problemSpecs.push({
      operation: "subtract",
      operandRanges: [
        [1000, 10000],
        [1000, 10000],
      ],
      params: { noRegrouping: false, negatives: false },
    });
  }
  return [
    {
      showNameField: true,
      sections: [
        {
          instructions: "Calculate the difference.",
          problems: problemSpecs,
        },
      ],
    },
  ];
};

export const generateQuickAddition = (): Array<Page> => {
  const problemSpecs: Array<Problem> = [];
  for (let i = 0; i < 24; i++) {
    problemSpecs.push({
      operation: "add",
      operandRanges: [
        [1000, 10000],
        [1000, 10000],
      ],
      params: { noRegrouping: false },
    });
  }
  return [
    {
      showNameField: true,
      sections: [
        {
          instructions: "Calculate the sum.",
          problems: problemSpecs,
        },
      ],
    },
  ];
};

const convertProblemToAPIProblem = (problemModel: ProblemSpec): Problem => {
  return {
    operation: problemModel.operation,
    params: { ...problemModel.params },
    operandRanges: problemModel.operands.map((pm) => {
      const [first, ...rest] = pm.range;
      return [first, rest.map((v) => v + 1)[0]];
    }),
  };
};

const convertGeneratedProblemsToAPIProblems = (
  generatedProblems: Array<GeneratedProblem>
): Array<Problem> => {
  return generatedProblems.map((gp) => {
    return { operands: [...gp.operands], operation: gp.operation };
  });
};

const convertProblemsSpecsToAPIProblems = (
  problemModels: Array<ProblemSpec>,
  randomize: boolean
): Array<Problem> => {
  const expanded = problemModels.reduce(
    (accumulator, problemSpec): Array<Problem> => {
      if (!problemSpec) {
        return accumulator;
      }
      const count = problemSpec.count ?? 1;
      for (let i = 0; i < count; i++) {
        const converted = convertProblemToAPIProblem(problemSpec);
        if (!converted) {
          continue;
        }
        accumulator.push(converted);
      }
      return accumulator;
    },
    [] as Array<Problem>
  );
  if (!randomize) {
    return expanded;
  }
  const ret: Array<Problem> = [];
  while (expanded.length) {
    const selected = expanded.splice(
      Math.floor(Math.random() * expanded.length),
      1
    );
    ret.push(...selected);
  }
  return ret;
};

export const convertSectionToAPIModel = (
  sectionModel: SectionSpec,
  state: PageState,
  ignoreGenerated: boolean = false
): Section => {
  let problems;
  if (!ignoreGenerated && sectionModel.generatedProblemIds?.length) {
    problems = convertGeneratedProblemsToAPIProblems(
      sectionModel.generatedProblemIds
        .map((p) => state.generatedProblemsById[p])
        .filter((p) => !!p)
    );
  } else {
    problems = convertProblemsSpecsToAPIProblems(
      sectionModel.problemIds
        .map((p) => state.problemsById[p])
        .filter((p) => !!p),
      sectionModel.mixProblems
    );
  }
  return {
    problems,
    instructions: !!sectionModel.instructions?.length
      ? sectionModel.instructions
      : undefined,
    title:
      sectionModel.title !== undefined && sectionModel.title !== null
        ? sectionModel.title
        : undefined,
    problemFontSize: sectionModel.problemFontSize ?? "sm",
  };
};

export const toAPIModel = (
  pageModels: Array<PageModel>,
  state: PageState
): Array<Page> => {
  if (!pageModels?.length) {
    return [];
  }
  return pageModels.map((pm): Page => {
    return {
      showNameField: pm.showNameField,
      sections: pm.sectionsIds
        .map((sid) => {
          return convertSectionToAPIModel(state.sectionsById[sid], state);
        })
        .filter((s) => !!s),
    };
  });
};
