import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  GeneratedProblem,
  PageSpec,
  ProblemSpec,
  SectionSpec,
  defaultProblem,
  defaultSection,
  previewStatus,
} from "../types";
import { getOperandSpec } from "../util/operation";
import { nextPageId, nextProblemId, nextSectionId } from "../utils";
export type PageModel = { id: string; sectionsIds: Array<string> } & Omit<
  PageSpec,
  "sections"
>;
export interface PageState {
  pages: Array<PageModel>;
  sectionsById: Record<string, SectionSpec>;
  problemsById: Record<string, ProblemSpec>;
  generatedProblemsById: Record<string, GeneratedProblem>;
}

const deleteProblem = (state: PageState, id: string): PageState => {
  const updatedProblemsById: Record<string, ProblemSpec> = {};
  Object.keys(state.problemsById).forEach((pid) => {
    if (pid !== id) {
      updatedProblemsById[pid] = state.problemsById[pid];
    }
  });
  state.problemsById = updatedProblemsById;
  return { ...state };
};

const deleteSection = (state: PageState, id: string): PageState => {
  const section = state.sectionsById[id];
  section?.problemIds?.forEach((pid) => {
    state = deleteProblem(state, pid);
  });
  const updatedSectionsById: Record<string, SectionSpec> = {};
  Object.keys(state.sectionsById).forEach((sid) => {
    if (sid !== id) {
      updatedSectionsById[sid] = state.sectionsById[sid];
    }
  });
  state.sectionsById = updatedSectionsById;
  return { ...state };
};

const deletePage = (state: PageState, id: string): PageState => {
  const index = state.pages.findIndex((pm) => pm.id === id);
  if (index < 0) {
    return state;
  }
  let localState: PageState = { ...state };
  state.pages[index].sectionsIds.forEach((sid) => {
    localState = deleteSection(localState, sid);
  });

  localState.pages = localState.pages.filter((p) => p.id !== id);
  localState.pages = localState.pages.map((p, i) => {
    return { ...p, num: i };
  });
  return { ...localState };
};

export const newProblem = (state: PageState): [ProblemSpec, PageState] => {
  const p = { ...defaultProblem, id: nextProblemId() };
  p.operands = [
    { ...getOperandSpec(p, 0), range: [0, 12] },
    { ...getOperandSpec(p, 1), range: [0, 12] },
  ];
  return [p, { ...state, problemsById: { ...state.problemsById, [p.id]: p } }];
};

const newSection = (state: PageState): [SectionSpec, PageState] => {
  const [p, localState] = newProblem(state);
  const s: SectionSpec = {
    ...defaultSection,
    id: nextSectionId(),
    problemIds: [p.id],
  };
  return [
    s,
    { ...localState, sectionsById: { ...localState.sectionsById, [s.id]: s } },
  ];
};

const newPage = (state: PageState): [PageModel, PageState] => {
  const [s, localState] = newSection(state);
  const p: PageModel = {
    id: nextPageId(),
    num: localState.pages.length,
    showNameField: true,
    sectionsIds: [s.id],
  };
  localState.pages.push(p);
  return [p, { ...localState, pages: [...localState.pages] }];
};

const initialState: PageState = {
  pages: [],
  sectionsById: {},
  problemsById: {},
  generatedProblemsById: {},
};

export const pageSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    addPage: (state, action: PayloadAction<void>) => {
      const s = newPage(state)[1];

      state.problemsById = s.problemsById;
      state.sectionsById = s.sectionsById;
      state.pages = s.pages;
    },
    removePage: (state, action: PayloadAction<string>) => {
      if (state.pages.length > 1) {
        return deletePage(state, action.payload);
      }
    },
    updatePage: (state, action: PayloadAction<PageModel>) => {
      const index = state.pages.findIndex((p) => p?.num === action.payload.num);
      if (index > -1) {
        state.pages[index] = { ...action.payload };
      }
    },
    addSection: (state, action: PayloadAction<number>) => {
      const pageNum = action.payload;
      if (state.pages.length >= pageNum) {
        const [s, localState] = newSection(state);
        console.log("adding section");
        const updatedPages = [...localState.pages];
        const updatedSectionIds = [...updatedPages[pageNum].sectionsIds, s.id];
        updatedPages[pageNum] = {
          ...updatedPages[pageNum],
          sectionsIds: updatedSectionIds,
        };
        // localState.pages[pageNum].sectionsIds.push(s.id);
        return { ...localState, pages: updatedPages };
      }
    },
    removeSection: (state, action: PayloadAction<[number, string]>) => {
      const [pageNum, sectionId] = action.payload;
      if (state.pages.length >= pageNum) {
        const updatedSectionsById = { ...state.sectionsById };
        delete updatedSectionsById[sectionId];
        const updatedPages = [...state.pages];
        const updatedSectionIds = updatedPages[pageNum].sectionsIds.filter(
          (sid) => sid !== sectionId
        );
        updatedPages[pageNum] = {
          ...updatedPages[pageNum],
          sectionsIds: updatedSectionIds,
        };

        return {
          ...state,
          sectionsById: updatedSectionsById,
          pages: updatedPages,
        };
      }
    },
    updateSection: (state, action: PayloadAction<SectionSpec>) => {
      const section = action.payload;
      state.sectionsById[section.id] = section;
    },
    addProblemSpec: (state, action: PayloadAction<[string, ProblemSpec]>) => {
      const [sectionId, problem] = action.payload;
      const section = state.sectionsById[sectionId];
      if (!section) {
        return state;
      }

      let problemList = [...section.problemIds] ?? [];
      section.problemIds = [...problemList, problem.id];
      state.problemsById = { ...state.problemsById, [problem.id]: problem };
      state.sectionsById = {
        ...state.sectionsById,
        [sectionId]: {
          ...section,
          generatedProblemIds: [],
          problemPreviewStatus: "none" as previewStatus,
        },
      };
      // return { ...state };
    },
    removeProblemSpec: (state, action: PayloadAction<[string, string]>) => {
      const [sectionId, problemId] = action.payload;
      const section = { ...state.sectionsById[sectionId] };
      if (!section) {
        return state;
      }
      const localState = { ...state }; //deleteProblem(state, problemId);
      const updatedSectionsById = { ...localState.sectionsById };
      const updatedProblemIds = {
        ...updatedSectionsById,
        [sectionId]: {
          ...updatedSectionsById[sectionId],
          generatedProblemIds: [],
          problemPreviewStatus: "none" as previewStatus,
          problemIds: updatedSectionsById[sectionId].problemIds.filter(
            (p) => p !== problemId
          ),
        },
      };
      return { ...localState, sectionsById: updatedProblemIds };
    },
    updateProblemSpec: (state, action: PayloadAction<ProblemSpec>) => {
      const problem = action.payload;
      if (!problem) {
        return state;
      }
      const sectionId = Object.keys(state.sectionsById).find((sectionId) => {
        return (state.sectionsById[sectionId].problemIds ?? []).find(
          (p) => p === problem.id
        );
      });
      if (sectionId) {
        const section = state.sectionsById[sectionId];
        state.sectionsById = {
          ...state.sectionsById,
          [sectionId]: {
            ...section,
            generatedProblemIds: [],
            problemPreviewStatus: "none" as previewStatus,
          },
        };
      }
      state.problemsById[problem.id] = problem;
    },
    addGeneratedProblems: (
      state,
      action: PayloadAction<Array<GeneratedProblem>>
    ) => {
      const updatedRecord: Record<string, GeneratedProblem> = {};
      if (action.payload) {
        action.payload.forEach((problem) => {
          if (problem) {
            updatedRecord[problem.id] = problem;
          }
        });
      }
      state.generatedProblemsById = {
        ...state.generatedProblemsById,
        ...updatedRecord,
      };
    },
    updateGeneratedProblem: (
      state,
      action: PayloadAction<Record<string, GeneratedProblem>>
    ) => {
      if (action.payload) {
        state.generatedProblemsById = {
          ...state.generatedProblemsById,
          ...action.payload,
        };
      }
    },
    removedGeneratedProblems: (state, action: PayloadAction<Array<string>>) => {
      if (action.payload) {
        const toDeleteRecord: Record<string, boolean> = {};
        action.payload.forEach((id: string) => {
          toDeleteRecord[id] = true;
        });
        const ret: Record<string, GeneratedProblem> = {};
        Object.keys(state.generatedProblemsById)
          .filter((id) => !toDeleteRecord[id])
          .forEach((id) => {
            ret[id] = state.generatedProblemsById[id];
          });
        state.generatedProblemsById = ret;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addPage,
  removePage,
  updatePage,
  addSection,
  removeSection,
  addProblemSpec,
  removeProblemSpec,
  updateProblemSpec,
  updateSection,
  addGeneratedProblems,
  removedGeneratedProblems,
  updateGeneratedProblem,
} = pageSlice.actions;

export const PageReducer = pageSlice.reducer;
