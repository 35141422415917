import { Option, Select, Stack, Typography } from "@mui/joy";
import { OperandSpec, ProblemSpec } from "../types";
import { ReactElement, useCallback, useId, useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateProblemSpec } from "../store/pageSlice";
import { Operand } from "./Operand";

export interface AdditionProblemProps {
  model: ProblemSpec;
  sectionId: string;
}
export const AdditionProblem = ({ model }: AdditionProblemProps) => {
  const dispatch = useDispatch();
  const selectId = useId();

  const onOperandChange = useCallback(
    (operand: OperandSpec, index: number) => {
      const updatedOperands = [...model.operands];
      updatedOperands[index] = operand;
      dispatch(updateProblemSpec({ ...model, operands: updatedOperands }));
    },
    [dispatch, model]
  );
  const onRegroupingChange = useCallback(
    (_evt: any, newValue: boolean | null) => {
      const updatedValue = newValue === null ? false : newValue;
      const updatedModel: ProblemSpec = {
        ...model,
        params: { ...model.params, regrouping: updatedValue },
      };
      dispatch(updateProblemSpec(updatedModel));
    },
    [dispatch, model]
  );
  const regrouping = useMemo(() => {
    return model?.params?.["regrouping"] === undefined
      ? true
      : model?.params?.["regrouping"];
  }, [model]);
  const operandContent: Array<ReactElement> = model.operands.map(
    (operand, index) => {
      const ix = index;
      return (
        <Operand
          onChange={(op) => {
            onOperandChange(op, ix);
          }}
          key={index}
          operand={operand}
        />
      );
    }
  );
  return (
    <Stack key={model.id}>
      <Typography color="primary" level="body-xs">
        Regrouping
      </Typography>
      <Select
        sx={{ width: "200px" }}
        value={regrouping}
        onChange={onRegroupingChange}
        slotProps={{ button: { id: selectId } }}
      >
        <Option value={true}>Some</Option>
        <Option value={false}>None</Option>
      </Select>
      {operandContent}
    </Stack>
  );
};
