import { Input, Stack, Typography } from "@mui/joy";
import { Calculators, GeneratedProblem, Operations } from "../types";
import { Fragment, useCallback, useMemo } from "react";
import { useAppDispatch } from "../store/hooks";
import { updateGeneratedProblem } from "../store/pageSlice";

export interface ProblemEditorProps {
  problem: GeneratedProblem;
  problemNumber: number;
}

export const ProblemEditor = ({
  problem,
  problemNumber,
}: ProblemEditorProps) => {
  const dispatch = useAppDispatch();
  const onOperandValueChange = useCallback(
    (value: string, operandIndex: number) => {
      const updatedOperands = [...problem.operands];
      const asNum = parseFloat(value);
      updatedOperands[operandIndex] = !isNaN(asNum) ? asNum : 0;
      const calculator =
        Calculators[Operations.findIndex((o) => o === problem.operation) ?? 0];
      const solution = calculator(updatedOperands);
      const updated: Record<string, GeneratedProblem> = {
        [problem.id]: { ...problem, operands: updatedOperands, solution },
      };
      dispatch(updateGeneratedProblem(updated));
    },
    [dispatch, problem]
  );
  const operands = useMemo(() => {
    return problem.operands.map((op, ix, arr) => {
      return (
        <Stack direction={"row"} gap={2}>
          {ix === 0 && (
            <Typography color="primary" level="body-lg">
              {problemNumber + ". "}
            </Typography>
          )}
          <Input
            value={op}
            variant="outlined"
            size="sm"
            onChange={(evt) => {
              onOperandValueChange(evt.target.value, ix);
            }}
            sx={{ width: "6rem" }}
          />
          {ix !== arr.length - 1 ? (
            <Typography color="primary" level="body-lg">
              {problem.operator}
            </Typography>
          ) : (
            <Fragment />
          )}
        </Stack>
      );
    });
  }, [problem, onOperandValueChange, problemNumber]);
  return (
    <Stack direction={"row"} gap={2} p={".25rem"}>
      {operands}
      <Typography color="primary" level="body-xs">
        =
      </Typography>{" "}
      <Typography color="primary" level="body-lg">
        {problem.solution ?? ""}
      </Typography>
    </Stack>
  );
};
