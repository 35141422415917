import {
  Button,
  Card,
  Box,
  Typography,
  Stack,
  LinearProgress,
  Link,
  Grid,
  CardOverflow,
  SvgIcon,
  CardContent,
  Checkbox,
} from "@mui/joy";
import { useState, useCallback, Fragment, useMemo } from "react";
import { generateProblems } from "../http/api";
import {
  generateQuickAddition,
  generateQuickDivision,
  generateQuickMultiplication,
  generateQuickMultiplication5,
  generateQuickSubtraction,
} from "../util/problems";
import { Page } from "../types";
import { generateDateFilenameSuffix } from "../util/util";
import { ScienceRounded } from "@mui/icons-material";

export interface QuickActionsProps {
  onJumpToCustom: () => void;
}

type quickAction = {
  func: () => Array<Page>;
  name: string;
  label: string;
  num: number;
};

const quickActions: Array<quickAction> = [
  {
    func: generateQuickMultiplication5,
    name: "Quick Multiplication Five Days",
    label: "Basic Multiplication - 5 Full Pages",
    num: 0,
  },
  {
    func: generateQuickMultiplication,
    name: "Quick Multiplication",
    label: "Basic Multiplication - Full Page",
    num: 1,
  },
  {
    func: generateQuickDivision,
    name: "Quick Division",
    label: "Basic Division - Full Page",
    num: 2,
  },
  {
    func: generateQuickSubtraction,
    name: "Four-digit Subtraction",
    label: "Four-digit Subtraction - 24 problems",
    num: 3,
  },
  {
    func: generateQuickAddition,
    name: "Four-digit Addition",
    label: "Four-digit Addition - 24 problems",
    num: 4,
  },
];

export const QuickActions = ({ onJumpToCustom }: QuickActionsProps) => {
  const [buttonBusy, setButtonBusy] = useState(-1);
  const [keysEnabledNums, setKeysEnabledNums] = useState<
    Record<number, boolean>
  >({});
  const [errorMsg, setErrorMsg] = useState("");
  const onButtonClick = useCallback(
    async (whichAction: quickAction): Promise<void> => {
      setButtonBusy(whichAction.num);
      setErrorMsg("");
      const { name, func, num } = whichAction;
      const keyEnabled = keysEnabledNums[num] === true;
      const fileName = `${name}-${generateDateFilenameSuffix()}.pdf`;
      if (func !== undefined) {
        try {
          await generateProblems(
            func(),
            fileName,
            undefined,
            undefined,
            keyEnabled
          );
        } catch (e: any) {
          setErrorMsg(e.message ?? e.toString() ?? "Request failed");
        }
      }
      setButtonBusy(-1);
    },
    [setButtonBusy, setErrorMsg, keysEnabledNums]
  );

  const onKeyEnabledClick = useCallback(
    (whichNum: number, enabled: boolean) => {
      setKeysEnabledNums({ ...keysEnabledNums, [whichNum]: enabled });
    },
    [setKeysEnabledNums, keysEnabledNums]
  );

  const actions = useMemo(() => {
    return quickActions.map((action) => {
      return (
        <Grid key={action.name}>
          <Card
            sx={{ minWidth: "400px" }}
            orientation="horizontal"
            variant="outlined"
          >
            <CardOverflow
              variant="solid"
              color="primary"
              sx={{
                flex: "0 0 60px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <SvgIcon sx={{ fontSize: "64px" }}>
                <ScienceRounded sx={{ color: "#FFFFFF" }} />
              </SvgIcon>
            </CardOverflow>
            <CardContent sx={{ pl: "1.5rem" }}>
              <Typography noWrap={true} level="title-md">
                {action.label}
              </Typography>
              <Checkbox
                size="sm"
                sx={{ pt: ".5rem" }}
                label="include answer key"
                onChange={(evt) => {
                  onKeyEnabledClick(action.num, evt.target.checked);
                }}
              />
              <Stack sx={{ pt: "1.5rem" }}>
                {buttonBusy !== action.num ? (
                  <Button
                    sx={{ maxWidth: "150px" }}
                    size="sm"
                    onClick={() => {
                      onButtonClick(action);
                    }}
                  >
                    Download
                  </Button>
                ) : (
                  <LinearProgress />
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      );
    });
  }, [buttonBusy, onButtonClick, onKeyEnabledClick]);

  return (
    <Stack
      spacing={4}
      sx={{
        display: "flex",
        minWidth: "380px",
        maxWidth: "100%",
        mx: "auto",
        px: {
          xs: 2,
          md: 6,
        },
        py: {
          xs: 0,
          md: 0,
        },
      }}
    >
      <Card
        sx={{ mt: 0 }}
        color="primary"
        invertedColors={true}
        variant="solid"
      >
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">Quick PDF Printables</Typography>
          <Typography level="body-md">
            Generate a quick worksheet with just one click.
          </Typography>
          <Typography color="primary" level="body-sm" pt="0.25rem">
            The problems are different each time, so click the button again to
            generate a fresh set of problems.
            <br /> If you need to customize your worksheet&nbsp;
            <Link
              component={"button"}
              onClick={() => {
                onJumpToCustom();
              }}
            >
              jump over to our customization tool
            </Link>
          </Typography>
          {errorMsg ? (
            <Typography level="title-md" color="danger">
              `Error: ${errorMsg}`
            </Typography>
          ) : (
            <Fragment />
          )}
        </Box>
      </Card>
      <Grid
        justifyContent={"center"}
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 1, sm: 2, md: 5 }}
        sx={{ flexGrow: 1 }}
      >
        {actions}
      </Grid>
    </Stack>
  );
};
