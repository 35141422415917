import {
  Box,
  Card,
  Typography,
  Button,
  Stack,
  CardContent,
  CardOverflow,
  Checkbox,
} from "@mui/joy";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ArticleIcon from "@mui/icons-material/Article";
import { useCallback, useEffect, useRef, useState } from "react";
import { Page } from "./Page";
import { addPage } from "../store/pageSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectPages } from "../store/pageSelector";
import { useStore } from "react-redux";
import { RootState } from "../store/store";
import { generateProblems } from "../http/api";
import { generateDateFilenameSuffix } from "../util/util";
import { toAPIModel } from "../util/problems";
import { Divider } from "@mui/material";
export interface CustomGenerationProps {}
export const CustomGeneration = (_props: CustomGenerationProps) => {
  const store = useStore<RootState>();
  const dispatch = useAppDispatch();
  const pages = useAppSelector(() => selectPages(store.getState().pages));
  const [problemNumbers, setProblemNumbers] = useState(false);
  const [generateKey, setGenerateKey] = useState(true);
  const firstPageRef = useRef(false);
  const add = useCallback(() => {
    dispatch(addPage());
  }, [dispatch]);
  useEffect(() => {
    if (!firstPageRef.current && !pages?.length) {
      dispatch(addPage());
    }
    return () => {
      firstPageRef.current = true;
    };
  }, [dispatch, pages]);
  const onGenerateWorksheet = useCallback(() => {
    const fileName = `CustomWorksheet-${generateDateFilenameSuffix()}.pdf`;
    const convertedPages = toAPIModel(pages, store.getState().pages);
    generateProblems(
      convertedPages,
      fileName,
      true,
      problemNumbers,
      generateKey
    );
  }, [pages, problemNumbers, generateKey, store]);
  const content: Array<React.ReactNode> = [];
  for (let i = 0; i < pages.length; i++) {
    content.push(<Page key={i} index={i} />);
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card sx={{ minWidth: "300px", maxWidth: "100%" }}>
        <Box>
          <Card variant="solid" color="primary" invertedColors={true}>
            <CardContent>
              <Typography level="title-lg" startDecorator={<ArticleIcon />}>
                Generate a Custom Worksheet
              </Typography>
              <Typography level="body-sm">
                Add as many pages as you want. Each page has one or more
                sections that contain one or more types of problems.
              </Typography>
            </CardContent>
            <CardOverflow>
              <Stack direction="row" gap={1} pb="1rem">
                <Typography
                  component="label"
                  color="neutral"
                  level="body-sm"
                  endDecorator={
                    <Checkbox
                      sx={{ ml: 1 }}
                      checked={generateKey}
                      onChange={(evt) => {
                        setGenerateKey(evt.target.checked);
                      }}
                    />
                  }
                >
                  Answer key
                </Typography>{" "}
                <Divider orientation="vertical" />
                <Typography
                  component="label"
                  color="neutral"
                  level="body-sm"
                  endDecorator={
                    <Checkbox
                      sx={{ ml: 1 }}
                      checked={problemNumbers}
                      onChange={(evt) => {
                        setProblemNumbers(evt.target.checked);
                      }}
                    />
                  }
                >
                  Show problem numbering
                </Typography>
              </Stack>
            </CardOverflow>
          </Card>
          {content}
          <Stack pt={"2rem"} direction={"row"}>
            <Button size="sm" variant="soft" onClick={add}>
              Add Page
            </Button>
            <Box flexGrow={4} />
            <Button
              size="lg"
              color="primary"
              startDecorator={<DownloadForOfflineIcon />}
              onClick={onGenerateWorksheet}
            >
              Download worksheet
            </Button>
          </Stack>
        </Box>
      </Card>
    </Box>
  );
};
