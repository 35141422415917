import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Switch,
  Typography,
} from "@mui/joy";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectPages, selectSections } from "../store/pageSelector";
import { useStore } from "react-redux";
import { RootState } from "../store/store";
import { SectionSpec, fontSize, fontSizes } from "../types";
import {
  PageModel,
  addProblemSpec,
  addSection,
  newProblem,
  removePage,
  removeSection,
  updatePage,
  updateSection,
} from "../store/pageSlice";
import Close from "@mui/icons-material/Close";
import Add from "@mui/icons-material/Add";
import TextDecrease from "@mui/icons-material/TextDecrease";
import TextIncrease from "@mui/icons-material/TextIncrease";
import Preview from "@mui/icons-material/Preview";
import {
  useCallback,
  useId,
  useMemo,
  useRef,
  useState,
  ChangeEvent,
  Fragment,
} from "react";
import { Problem } from "./Problem";
import { ProblemPreview } from "./ProblemPreview";
import { generatePreview } from "../http/api";
export interface PageProps {
  index: number;
}
export const Page = ({ index }: PageProps) => {
  const id = useId();
  const store = useStore<RootState>();
  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const previewPendingRef = useRef<boolean>(false);
  const sections: Array<SectionSpec> = useAppSelector(() => {
    return selectSections(store.getState().pages, index);
  });
  const pages: Array<PageModel> = useAppSelector(() =>
    selectPages(store.getState().pages)
  );
  const page = pages[index];
  const onShowNameChange = useCallback(
    (evt: ChangeEvent) => {
      const checked = (evt.target as HTMLInputElement).checked;
      dispatch(updatePage({ ...page, showNameField: checked }));
    },
    [dispatch, page]
  );
  const onRemovePage = useCallback(
    (id: string) => {
      dispatch(removePage(id));
    },
    [dispatch]
  );

  const onInstructionChange = useCallback(
    (value: string, section: SectionSpec) => {
      dispatch(updateSection({ ...section, instructions: value }));
    },
    [dispatch]
  );

  const onTitleChange = useCallback(
    (value: string, section: SectionSpec) => {
      dispatch(updateSection({ ...section, title: value }));
    },
    [dispatch]
  );

  const onMixProblemsChange = useCallback(
    (value: boolean, section: SectionSpec) => {
      dispatch(
        updateSection({
          ...section,
          generatedProblemIds: [],
          problemPreviewStatus: "none",
          mixProblems: value,
        })
      );
    },
    [dispatch]
  );

  const onAddSection = useCallback(() => {
    dispatch(addSection(index));
  }, [dispatch, index]);

  const onAddProblem = useCallback(
    (sectionId: string) => {
      const [prob] = newProblem(store.getState().pages);
      dispatch(addProblemSpec([sectionId, prob]));
    },
    [dispatch, store]
  );

  const onPreview = useCallback(
    async (section: SectionSpec) => {
      if (!previewPendingRef.current) {
        previewPendingRef.current = true;
        dispatch(updateSection({ ...section, problemPreviewStatus: "busy" }));
        setAccordionExpanded(true);
        try {
          await generatePreview(section.id, store, true);
        } finally {
          previewPendingRef.current = false;
        }
      }
    },
    [dispatch, store]
  );

  const onRemoveSection = useCallback(
    (pageNum: number, sectionId: string) => {
      dispatch(removeSection([pageNum, sectionId]));
    },
    [dispatch]
  );

  const onTextSizeChange = useCallback(
    (increase: boolean, section: SectionSpec) => {
      let curSize: fontSize = section.problemFontSize ?? "sm";
      const curIndex = fontSizes.findIndex((s: fontSize) => s === curSize);
      let value: fontSize = curSize;
      if (increase) {
        if (curIndex < fontSizes.length - 1) {
          value = fontSizes[curIndex + 1];
        }
      } else {
        if (curIndex > 0) {
          value = fontSizes[curIndex - 1];
        }
      }
      dispatch(updateSection({ ...section, problemFontSize: value }));
    },
    [dispatch]
  );

  const sectionContent = useMemo(
    () =>
      sections?.map((section, i) => {
        if (!section) {
          return null;
        }
        const problemControls = section.problemIds?.map((p) => (
          <Problem key={i} sectionId={section.id} problemId={p} />
        ));
        const problemPreview =
          section.problemPreviewStatus !== undefined &&
          section.problemPreviewStatus !== "none" ? (
            <Accordion
              variant="outlined"
              expanded={accordionExpanded}
              sx={{
                padding: ".5rem",
                borderBottom:
                  "1px solid var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))",
              }}
              onChange={(_, expanded) => {
                setAccordionExpanded(!!expanded);
              }}
            >
              <AccordionSummary>Section preview</AccordionSummary>
              <AccordionDetails>
                <ProblemPreview section={section} />
              </AccordionDetails>
            </Accordion>
          ) : (
            <Fragment />
          );
        return (
          <Box key={i} py={"1rem"}>
            <div>
              <Divider />
              <Stack
                pt=".5rem"
                direction={"row"}
                justifyContent={"right"}
                gap={1}
              >
                <Typography level="body-xs" textAlign={"right"}>
                  Section {i + 1}
                </Typography>
                {sections.length > 1 ? (
                  <IconButton
                    onClick={() => {
                      onRemoveSection(page.num, section.id);
                    }}
                    disabled={sections.length === 1}
                    variant="outlined"
                    sx={{
                      "--IconButton-size": "12px",
                    }}
                  >
                    <Close />
                  </IconButton>
                ) : (
                  <Fragment />
                )}
              </Stack>
              <FormControl>
                <FormLabel>Section title:</FormLabel>
                <Input
                  size="sm"
                  placeholder="Add a title to this section"
                  value={section.title ?? "Instructions: "}
                  onChange={(evt) => {
                    onTitleChange(evt.target.value, section);
                  }}
                />
              </FormControl>
              <FormControl sx={{ pt: "0.75rem", pb: "1rem" }}>
                <FormLabel>Section instructional text:</FormLabel>
                <Input
                  size="sm"
                  placeholder="Leave blank to omit instructional text"
                  value={section.instructions}
                  onChange={(evt) => {
                    onInstructionChange(evt.target.value, section);
                  }}
                />
              </FormControl>
              <Stack direction={"row"} pt={"1rem"}>
                <Stack direction={"row"} flexShrink={1}>
                  <IconButton
                    size="sm"
                    color="primary"
                    variant="plain"
                    disabled={
                      fontSizes.findIndex(
                        (s: fontSize) => s === (section.problemFontSize ?? "sm")
                      ) === 0
                    }
                    onClick={() => {
                      onTextSizeChange(false, section);
                    }}
                  >
                    <TextDecrease />
                  </IconButton>
                  <Input
                    size="sm"
                    readOnly={true}
                    value={fontSizeToLabel(section.problemFontSize ?? "sm")}
                    sx={{ maxWidth: "5rem", justifyContent: "center" }}
                  />
                  <IconButton
                    size="sm"
                    color="primary"
                    variant="plain"
                    disabled={
                      fontSizes.findIndex(
                        (s: fontSize) => s === (section.problemFontSize ?? "sm")
                      ) >=
                      fontSizes.length - 1
                    }
                    onClick={() => {
                      onTextSizeChange(true, section);
                    }}
                  >
                    <TextIncrease />
                  </IconButton>
                </Stack>
                <Stack
                  pt={".75rem"}
                  direction={"row"}
                  gap={1}
                  flexGrow={1}
                  justifyContent={"right"}
                >
                  <Typography color="primary" level="body-xs">
                    Mix multiple problem types
                  </Typography>
                  <Switch
                    checked={section.mixProblems}
                    onChange={(evt) => {
                      onMixProblemsChange(evt.target.checked, section);
                    }}
                  />
                </Stack>
              </Stack>
              {problemControls}
              <Box py={".75rem"}>
                <Button
                  startDecorator={<Add />}
                  onClick={() => {
                    onAddProblem(section.id);
                  }}
                  size="sm"
                  variant="outlined"
                >
                  Add problem type
                </Button>
              </Box>
              {problemPreview}
              <Stack direction={"row"} py={".75rem"}>
                <Box pt={"1rem"}>
                  <Button
                    startDecorator={<Preview />}
                    disabled={section.problemPreviewStatus === "busy"}
                    onClick={() => {
                      onPreview(section);
                    }}
                    size="sm"
                    variant="solid"
                  >
                    {section.problemPreviewStatus === undefined ||
                    section.problemPreviewStatus === "none"
                      ? "Preview section problems"
                      : "Regenerate preview"}
                  </Button>
                </Box>
                <Box flexGrow={2} />
                <Stack flexShrink={1}>
                  <Button
                    startDecorator={<Add />}
                    onClick={onAddSection}
                    size="sm"
                    variant="plain"
                  >
                    Add section
                  </Button>
                </Stack>
              </Stack>
            </div>
          </Box>
        );
      }) ?? [],
    [
      sections,
      page,
      accordionExpanded,
      setAccordionExpanded,
      onAddProblem,
      onAddSection,
      onInstructionChange,
      onMixProblemsChange,
      onRemoveSection,
      onTitleChange,
      onTextSizeChange,
      onPreview,
    ]
  );
  return (
    <Box key={page.id}>
      <div>
        <Stack pt={"1rem"} direction={"row"} gap={1}>
          {pages.length > 1 ? (
            <IconButton
              onClick={() => {
                onRemovePage(page.id);
              }}
              disabled={pages.length === 1}
              variant="outlined"
              sx={{
                "--IconButton-size": "14px",
              }}
            >
              <Close />
            </IconButton>
          ) : (
            <Fragment />
          )}
          <Typography level="title-lg" color="primary">
            Page {index + 1}
          </Typography>
        </Stack>
        <Stack pt=".5rem" direction={"row"} gap={1}>
          <Typography color="primary" level="body-sm">
            Show name line:
          </Typography>
          <Switch
            id={id}
            onChange={onShowNameChange}
            checked={page?.showNameField}
          />
        </Stack>
        {sectionContent}
      </div>
    </Box>
  );
};

const fontSizeToLabel = (size: fontSize): string => {
  switch (size) {
    case "xs":
      return "tiny";
    case "sm":
      return "small";
    case "m":
      return "medium";
    case "l":
      return "large";
    case "xl":
      return "jumbo";
  }
  return "small";
};
