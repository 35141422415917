import { Stack } from "@mui/joy";
import { OperandSpec, ProblemSpec } from "../types";
import { ReactElement, useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateProblemSpec } from "../store/pageSlice";
import { Operand } from "./Operand";

export interface MultiplicationProblemProps {
  model: ProblemSpec;
  sectionId: string;
}
export const MultiplicationProblem = ({
  model,
}: MultiplicationProblemProps) => {
  const dispatch = useDispatch();
  console.log(JSON.stringify(model));

  const onOperandChange = useCallback(
    (operand: OperandSpec, index: number) => {
      const updatedOperands = [...model.operands];
      updatedOperands[index] = operand;
      dispatch(updateProblemSpec({ ...model, operands: updatedOperands }));
    },
    [dispatch, model]
  );

  const operandContent: Array<ReactElement> = model.operands.map(
    (operand, index) => {
      const ix = index;
      return (
        <Operand
          onChange={(op) => {
            onOperandChange(op, ix);
          }}
          key={index}
          operand={operand}
        />
      );
    }
  );
  return <Stack key={model.id}>{operandContent}</Stack>;
};
